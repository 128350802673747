import {memo,Fragment} from 'react'


const SettingOffCanvas = memo((props) => {
    return (
        <Fragment>
            {/* Demo Purpose */}
        </Fragment>
    )
})

SettingOffCanvas.displayName = 'SettingOffCanvas'
export default SettingOffCanvas
